
.lcs-main{
  background-color: #EFEFEF!important;
  margin-top: 50px;
}
.lcs-main-page-body{
  background: transparent !important;
  padding: 5rem 1rem 1rem 5rem !important;
}
.lsc-description{
  font-size: 18px;
  line-height: 1.4em;
  color: #888;
}

.lsc-img-calc{
  width: 50px;
  opacity: .6;
  margin-right: 30px;
}
.lsc-page-header{
  padding-top: 40px;
  padding-left: 0;
}
.toggle-icon-btn {
  height: 120px;
  width: 120px;
  background-color: white;
  border-radius: 5px;
  transition: all .4s ease;
}

.logging-calc-margin-top {
  margin-top: 20px;
}

.logging-calc-card-title-margin-left {
  margin-left: 10px;
}

.logging-calc-icon-btn {
  transition: all 0.3s linear;
}

.logging-calc-icon-btn-down {
  transform: rotate(90deg);
}

.logging-calc-ant-input {
  width: 160px;
}

.logging-calc-layout-col {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.logging-calc-layout-col .total-storage {
  font-size: xx-large;
  text-align: center;
}

.logging-calc-layout-col .total-text {
  text-align: end;
}
