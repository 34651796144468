html,
body {
  min-height: 100%;
}

#root.v2 {
  position: relative;
  z-index: 1;
}

/** Make root div direct child height 100% **/
#root.v2,
#root.v2 > div,
#root.v2 > div > .pan-spin-wrapper,
#root.v2 > div > .pan-spin-wrapper > .ant-spin-container {
  height: 100%;
}

.pan-loading-indicator {
  margin: -18px 0 0 0;
  top: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  color: #3391ff;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
}

.pan-loading-indicator > u {
  opacity: 0;
  display: inline-block;
  padding-left: 1px;
  color: #fff;
  width: 8px;
  height: 8px;
  background-color: #0ba4e8;
  border-radius: 20px;
  margin-right: 2px;
}

.pan-loading-indicator > u:nth-child(1) {
  animation: 2.5s ease-in-out .5s infinite lol;
}

.pan-loading-indicator > u:nth-child(2) {
  animation: 2.5s ease-in-out .4s infinite lol;
}

.pan-loading-indicator > u:nth-child(3) {
  animation: 2.5s ease-in-out .3s infinite lol;
}

.pan-loading-indicator > u:nth-child(4) {
  animation: 2.5s ease-in-out .2s infinite lol;
}

.pan-loading-indicator > u:nth-child(5) {
  animation: 2.5s ease-in-out .1s infinite lol;
}

.pan-loading-indicator > u:nth-child(6) {
  animation: 2.5s ease-in-out infinite lol;
}

@keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }

  40%,
  60% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
}

.cortex-app-bar {
  --cortex-app-bar-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --cortex-app-bar-bg-image: none;
  --cortex-app-bar-bg-color: #0ba4e8;
  --cortex-app-bar-divider: rgba(255, 255, 255, .6);
}

.home.portal .cortex-app-bar,
.portal.app_details .cortex-app-bar {
  --cortex-app-bar-box-shadow: none;
  --cortex-app-bar-bg-color: transparent;
}

.v2 .cortex-app-bar-mock + .wrap {
  padding-top: 50px;
}

.v2 {
  .home.portal,
  .portal.app_details {
    .cortex-app-bar-mock + .wrap,
    .cortex-app-bar + .wrap {
      padding-top: 0;
    }
  }
}

.cortex-app-bar.hover,
.home.portal .cortex-app-bar.hover,
.portal.app_details .cortex-app-bar.hover {
  --cortex-app-bar-bg-color: #0ba4e8;
  --cortex-app-bar-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.v2-blue-bkg,
.portal.app_details .v2-blue-bkg.page-header,
.portal.home .apps.v2-blue-bkg {
  background-color: #009cdd;
  background-image: url('../images/hub-bkg.min.svg');
  background-size: cover;
}

.sparky-blue-bkg,
.portal.app_details .sparky-blue-bkg.page-header,
.portal.home .apps.sparky-blue-bkg {
  background: #1B212F;
}

.cortex-app-bar-mock {
  background: #0ba4e8;
  box-shadow: none;

  .utility-bar > button._pendo-badge_ {
    display: none !important;
  }

  .home &,
  .app_details & {
    background: transparent;
  }

  &.hover {
    background: #0ba4e8;
    box-shadow: 0 2px 4px rgb(0 0 0 / 0.2);
  }
}

.utility-bar .nav-link .fa {
  color: #fff;
  opacity: .8;
}

.utility-bar .nav-link:focus .fa,
.utility-bar .nav-link:hover .fa {
  opacity: 1;
}

.utility-bar .nav-link {
  vertical-align: middle;
  display: inline-block;
}

.utility-bar .nav-link.pan-dropdown::after {
  border: none;
  opacity: .8;
  background: url('../images/caret-down-white.min.svg') center right no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  vertical-align: 0;
  margin-left: 0;
}

.v2 .ant-form-item-required:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 10px;
  display: inline-block;
  background-color: #d94949;
  margin-right: 5px;
  top: -1px;
  position: relative;
}

.portal > .pan-spin-wrapper > .ant-spin-container.ant-spin-blur {
  opacity: .15;
}

.ant-form label {
  font-size: 12px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
}

.ant-form-item-label label:after {
  content: '';
}

.ant-form-item-label {
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .v2 .more,
  .v2 .other {
    padding: 33px 20px 70px 20px;
  }
}

.v2 .more h3,
.v2 .other h3 {
  margin: 0 0 22px 0;
  font-size: 26px;
  color: #1d2124;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#pan-menu-dropdown {
  left: 35px;
}

.page-header h1 {
  margin-bottom: 0;
  display: inline-block;
}

body.comfortable .page-header {
  padding: 15px 15px 0;
}

body.comfortable .page-body {
  padding: 15px;
}

body .card.full-height {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  body.comfortable .page-header {
    padding: 30px 34px 0;
  }

  body.comfortable .page-body {
    padding: 30px 34px;
  }

  /* AW: Tighten the space between the buttons and card */
  body.comfortable .card {
    margin-bottom: 15px;
  }

  body.comfortable .card:last-child {
    margin-bottom: 30px;
  }

  body.comfortable .card.full-height {
    min-height: -webkit-calc(100vh - 176px);
    min-height: -moz-calc(100vh - 176px);
    min-height: calc(100vh - 176px);
  }
}

body.logged-out #pan-menu::after {
  content: '';
}

#pan-nav .nav-item-user .dropdown-menu {
  margin-left: -5px;
}

#pan-nav .nav-item-user {
  margin-right: 0;
}

button.close:focus {
  outline: 0;
}

.table.no-borders th,
.table.no-borders td {
  border: 0;
}

.description-list-table th {
  width: 25%;
  text-align: right;
  font-weight: normal;
  color: #999;
}

.description-list-table .progress-container th,
.description-list-table .progress-container td {
  height: 60px;
  vertical-align: middle;
}

.form-check-label .form-check {
  padding-left: 18px;
}

.form-check-label .form-check:first-of-type {
  margin-top: .5rem;
}

.modal-header .btn {
  position: absolute;
  top: 22px;
  right: 15px;
  z-index: 1051;
}

.card-header-lightweight {
  background-color: transparent;
  padding: 1.25rem;
  border-bottom: 0;
  color: #888;
}

.card-header-lightweight + .card-body {
  padding-top: 0;
}

.card-body + .card-footer-lightweight {
  padding-top: 0;
}

.card-header-lightweight h1,
.card-header-lightweight h2,
.card-header-lightweight h3,
.card-header-lightweight h4,
.card-header-lightweight h5 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.card-header .card-controls {
  margin-right: -4px;
}

.card-header .card-controls > a {
  display: inherit;
}

.card-header .card-controls .fa {
  margin: 0 4px;
}

.card-footer-lightweight {
  background-color: transparent;
  padding: 1.25rem;
  border-top: 0;
  color: #888;
}

/* AW: Align buttons to edge of card */
.form-footer {
  padding: 0;
}

/* AW: Move inline style to linked style sheet */
.form-check-label {
  font-weight: normal;
  padding-left: 18px;
}

/* AW: Fix so label and checkbox baselines are aligned */
.form-check-input {
  margin-top: .2rem;
  margin-left: -18px;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

.expand-collapse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.expand-collapse > span {
  width: 10px;
  height: 16px;
  display: inline-block;
  text-align: center;
}

.indent {
  margin-top: 3px;
  margin-left: 14px;
  min-height: 16px;
}

.form-check-label .expand-collapse {
  left: -16px;
  position: relative;
}

/* AW: Copied inline styles from simple form mockup */
.container-900 .page-header {
  padding-top: 24px;
  padding-bottom: 6px;
}

/* AW: Copied from directorySync.css */
.instructional-text {
  font-size: 17px;
  color: #999;
}

/* AW: Copied from old style guide */
.card .table td:first-of-type,
.card .table th:first-of-type {
  padding-left: 1.2rem;
}

.card.comfortable h2 {
  margin: 0 0 1rem;
}

.card.comfortable hr {
  margin: 1.5rem 0;
}

.form-control-plaintext {
  width: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  color: #999;
}

.form-group.static {
  margin-bottom: 0;
}

.progress.progress-lg {
  margin-top: 4px;
  padding: 5px 15px 5px 5px;
  border-radius: 30px;
  height: 35px;
  position: relative;
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2), 0 1px 1px rgba(255, 255, 255, .3);
}

.progress.progress-lg .progress-bar {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 20px 0 0 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 5px;
}

.progress-bar.bg-warning {
  color: #333;
}

.progress-bar-label {
  padding: 0 3px;
}

.fa.status {
  font-size: 1.5rem;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 1px;
}

.status-ok {
  color: #28a745 !important;
}

.status-warning {
  color: #ffc107 !important;
}

.status-neutral {
  color: #999 !important;
}

.status-error {
  color: #dc3545 !important;
}

.warning {
  color: #d9534f;
  font-weight: 500;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.container .row {
  margin-right: -15px;
  margin-left: -15px;
}

.portal.home {
  background: #fff;
}

.portal #pan-nav {
  background-color: #009ace;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 500ms, box-shadow 500ms; /* Safari */
  transition: background-color 500ms, box-shadow 500ms;
}

.portal #pan-nav:hover,
.portal #pan-nav.hover {
  background-color: #444;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
}

.portal main {
  background: #1b262f;
}

.portal.app_details main {
  background: #fff;
}

.portal.home main .page-header {
  background: #009ace;
  text-align: center;
  padding-top: 4rem;
}

.apps,
.portal.app_details main .page-header {
  background: #009ace;
  background: -moz-linear-gradient(top, #009ace 0%, #86cbe4 100%);
  background: -webkit-linear-gradient(top, #009ace 0%, #86cbe4 100%);
  background: linear-gradient(to bottom, #009ace 0%, #86cbe4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#009ace', endColorstr='#86cbe4', GradientType=0);
}

.portal.app_details main .page-header {
  padding-bottom: 30px;
  color: #fff;
}

.portal.home main .page-body {
  padding: 0;
  background: #fff;
}

.portal.home .page-body .more {
  background: #f3fafe;
}

.portal.home.marketplace main .page-body {
  background: #86cbe4;
}

.portal.app_details main .page-body {
  background: #fff;
}

.portal h1 {
  font-style: normal;
  font-size: 48px;
  color: #48525a;
  font-weight: 100;
  line-height: 1.25em;
}

.portal.home main .page-header h1,
.portal.error main .page-header h1 {
  color: #fff;
  margin: 0 auto;
  text-align: center;
}

.portal h2 {
  margin: 0 0 1rem;
}

.portal hr {
  margin: 1.75em 0 1.25em;
}

.tabs-bg {
  background-color: #f9f9f9;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  padding: 1em 1.3em;
  transition: all 0.2s ease-in-out;
  color: #777;
  border: 1px solid transparent;
  border-width: 4px 1px 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.nav-tabs li:first-child .nav-link {
  border-left: 0;
}

.nav-tabs .nav-link:hover {
  background-color: #eceeef;
  border-color: #eceeef;
}

.nav-tabs .nav-link.active {
  border-top-color: #0e9ac8;
  color: #48525a;
}

.nav-tabs .nav-link.disabled {
  color: #b7b8b8;
}

.nav-tabs .nav-link.disabled:hover {
  background-color: transparent;
  border-color: transparent;
}

.nav-link {
  display: block;
  padding: 0.5em 1em;
}

/*
.nav-pills a.nav-link {
  border-radius: 30px;
  color: #464a4c;
  margin-right: 2px;
}

.nav-pills a.nav-link.active,
.nav-pills a.nav-link.active:hover {
  background-color: #d8e8e8;
  color: #333;
}

.tab-content .tab-content {
  padding: 1em 0 0;
}
*/

.page-body > .tab-content {
  padding: 1.3em;
  background: #fff;
}

.page-body > .tab-content {
  margin: 0 0 3rem;
  border-top: 1px solid #ddd;
}

#app .page-header {
  align-items: center;
  padding: 5rem 6rem;
}

#app .page-header {
  align-items: start;
}

#app .page-header h1 {
  font-size: 60px;
  font-weight: 100;
  color: #fff;
  margin-top: -5px 0 0;
}

#app .page-header .page-header-video {
  flex: 0 0 500px;
}

#app .page-header .page-header-video aside {
  display: block;
  position: relative;
  max-width: 100%;
  padding-top: 56.25%;
}

#app .page-header .page-header-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#app .page-body {
  padding: 5rem 6rem 1rem;
}

#app .app_wrapper {
  margin: 0 30px 0 0;
}

.app_content {
  margin: .5rem 0;
}

.app_content > .row {
  margin-bottom: 2rem;
}

.app_content > .row:first-of-type {
  padding: 0 1rem;
}

.byline {
  color: #0c88b0;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.app_summary {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 200;
  margin-right: 4rem;
}

.app_icon {
  margin: 32px auto 0;
  background-size: auto 100%;
  min-width: 72px;
  height: 72px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: middle;
}

.page-header .app_icon {
  margin: 0 1em 0 0;
  background-size: auto 100%;
  min-width: 72px;
  height: 72px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: middle;
}

.app_desc {
  background: #fff;
  margin: 0 0 1rem;
}

.app_desc .row:first-of-type {
  padding-top: 0;
}

.app_desc .row {
  padding: 1.5rem 1rem;
  border: 0;
}

.container.app_desc {
  margin: 0 -15px 1rem;
}

@media (min-width: 768px) {
  .container.app_desc {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container.app_desc {
    max-width: 100%;
  }
}

.app_content {
  margin: .5rem -30px;
  font-size: 17px;
  line-height: 1.4em;
  color: #48525a;
  font-weight: 300;
}

.app_content h4 {
  margin-bottom: 1rem;
}

.screenshot {
  border: 0;
  background: #fff;
  margin: 15px;
  text-align: center;
}

.screenshot img {
  margin: 0 auto;
  border: 0;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-shadow: 0 1px 25px 0 rgba(0, 154, 206, .2);
  box-shadow: 0 1px 25px 0 rgba(0, 154, 206, .2);
}

.app_requirements_outline {
  background: #f3fafc;
  padding: 20px;
  border-radius: 40px;
  margin-left: 2rem;
}

.app_requirements_wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}

.app_requirements {
  background: #fff;
  padding: 40px 20px;
  margin: -40px 0;
}

.app_requirements p:last-of-type {
  margin-bottom: 0;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.modal-content {
  border: 0 solid rgba(0, 0, 0, .2);
  border-radius: 0;
  box-shadow: 0 0 28px rgba(0, 0, 0, .5);
  border-top: 8px solid #0e9ac8;
}

.modal-header {
  border-bottom: 0;
  justify-content: flex-start;
  padding: 20px 15px;
}

.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  font-size: 1.8rem;
  font-weight: 400;
  color: #666;
  margin-bottom: 0;
}

.modal-title {
  line-height: 1.3;
}

.modal-header .close {
  margin-left: auto;
}

.modal-header .close span {
  font-size: 36px;
}

.modal-footer {
  justify-content: flex-end;
  border-top: 0 solid #eceeef;
  background-color: #f1f0f0;
  padding: .8rem 15px;
}

main.has-footer {
  display: flex;
  flex-direction: column;
}

main.has-footer > div {
  flex: 1 0 auto;
}

main.has-footer > footer {
  flex-shrink: 0;
}

@media screen and (min-width: 768px) {
  .screenshots .row:first-child .screenshot {
    margin-top: 0;
  }

  .screenshots .row:last-child .screenshot {
    margin-bottom: 0;
  }

  .screenshots .row .screenshot:first-child {
    margin-left: 0;
  }

  .screenshots .row .screenshot:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .apps,
  .more,
  .other,
  .info {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .apps h1,
  .more h3,
  .other h3 {
    margin: 1.5rem auto 3rem;
  }

  .more,
  .other {
    padding: 5rem 1.5rem;
  }

  .nav-item-org {
    border-left: 1px solid rgba(255, 255, 255, .5);
  }

  .new-top-nav .nav-item.nav-item-org > a.nav-link {
    text-transform: none;
    font-size: 18px;
    font-weight: 200;
  }

  .new-top-nav .nav-item.nav-item-org > a.nav-link.dropdown-toggle::after {
    font-size: 14px;
  }
}

.eula {
  font-size: .8em !important;
  line-height: 1.3em !important;
}

.eula-agreement-text {
  margin: .5rem 0 !important;
}

.instances {
  text-align: center;
}

.instances .card {
  width: 100%;
}

.portal .card h2 {
  margin: 0;
}

.instances-list {
  height: 460px;
  overflow-y: auto;
}

.instances .card-header-lightweight {
  padding: .75rem;
}

.instances .tenant-id {
  margin: -1px 5px 0;
}

.instances .card-body {
  text-align: left;
}

.instances .card-header-lightweight + .card-body {
  padding: 0 .75rem .75rem 2.4rem;
}

.instances .card-header h2 .badge {
  font-size: 14px;
  margin-left: .5rem;
  text-transform: none;
}

.instances .col .expand-collapse {
  width: 140px;
}

/*
.instances .col ul,
.instances-cell {
  list-style: none;
  padding: .1rem 0 0 .9rem;
  font-weight: bold;
}
*/

.instances .col ul ul,
.instances-cell ul {
  list-style: none;
  padding-left: 0;
  font-weight: normal;
}

.instances .col ul ul:last-of-type,
.instances-cell ul:last-of-type {
  margin-bottom: 0;
}

#region_warning {
  margin: .5rem 0 0;
  display: none;
}

#subdomain-input {
  width: 300px;
  display: inline-block;
}

.refresh-settings-btn {
  float: right;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.refresh-settings-btn:focus {
  outline: none !important;
}

.refresh-settings-btn > .pan-icon {
  color: #999;
  transition: color .2s linear;
  font-size: 24px;
}

.refresh-settings-btn:hover > .pan-icon {
  color: #777;
}

.refresh-settings-btn:disabled > .pan-icon,
.refresh-settings-btn:disabled:hover > .pan-icon {
  color: #999;
  cursor: not-allowed;
}

.pan-modal .ant-modal-body,
.pan-modal .ant-modal-footer {
  padding: 24px;
}

.pan-modal .form-footer .pan-button {
  min-width: 80px;
}

.pan-modal .ant-modal-body > .pan-page-body.page-body {
  padding: 0;
}

.pan-form > .form-footer {
  border-top: solid 1px #dadcde;
  padding-top: 24px;
}

// override default behavior
// loading button inside loading spinner should not show button spinner
.pan-spin-wrapper > .ant-spin-container.ant-spin-blur .ant-btn.ant-btn-loading {
  padding-left: .75rem;
  padding-right: .75rem;

  > .anticon-spin.anticon-loading {
    display: none;
  }

  > .anticon + span {
    margin-left: 0;
  }
}

// fix settings page scroll to top while loading
.cortex-app-bar + .wrap > .ant-col-24 > .pan-spin-wrapper > .ant-spin-container {
  overflow: auto;
}
