.activate-alert-banner.ant-alert-banner {
  color: #fff;
  min-height: 48px;
  line-height: 16px;
  padding: 16px 60px;

  > .ant-alert-icon {
    font-size: 16px;
    top: 16px;
    left: 30px;
    color: #fff;
  }

  > .ant-alert-close-icon {
    font-size: 20px;
    top: 12px;

    > .anticon-cross {
      color: #fff;
    }
  }

  &.ant-alert-success {
    background-color: #1faf2c;
  }

  &.ant-alert-error {
    background-color: #d94949;
  }

  &.ant-alert-warning {
    background-color: #f9a145;
  }

  &.ant-alert-info {
    background-color: #0a9cdd;
  }
}
