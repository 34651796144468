.auth-code-modal.pan-modal {
  top: 200px;

  .ant-modal-header {
    padding: 33px 20px 20px 20px;
    background-color: #fff;
    border-bottom: 0;

    .ant-modal-title {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      color: #1d2124;
    }
  }

  .ant-modal-body {
    padding: 7px 20px 15px 20px;
    text-align: center;
  }

  .ant-modal-close {
    opacity: .6;
    outline: none;

    .ant-modal-close-x::before {
      content: '';
      background: center no-repeat url('../../images/svg-icons/close-dark.svg');
      background-size: 16px 16px;
      height: 50px;
    }

    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: 1;
    }
  }

  .authcode-label {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .pan-input {
    width: 420px;
    text-align: center;
  }

  .pan-button {
    width: 100px;
    margin: 16px 0 23px 0;
  }
}
