.registration {
  .registration-form-header {
    margin-top: 18px;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
  }
  .password-check {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #808080;
  }
  .ant-form-item-label {
    text-transform: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    label {
      color: #333333;
    }
  }
}
