main.error-notice-page {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;

  .page-header h1 {
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 1.25em;
    padding-top: 10%;
  }

  .page-body {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;

    .icon {
      vertical-align: top;

      > svg.svg-icon {
        fill: #fff;
        width: 24px;
        height: 24px;
        margin-top: 6px;
      }
    }

    .text {
      margin-left: .5em;
      white-space: normal;
    }
  }
}

@media screen and (max-width: 512px) {
  main.error-notice-page {
    .page-header h1 {
      font-size: 32px;
    }

    .page-body {
      font-size: 14px;
    }
  }
}
