.role-table-bg {
  background-color: white;
}
.RBAC__all_apps{
  margin-top: -16px;
}
.RBAC__all_apps.ant-menu-submenu:hover{
 background-color: transparent;
}
.RBAC__all_apps .ant-menu-submenu-title .ant-menu-submenu-arrow{
  right: auto;
  margin-left: 20px;
}
.RoleTable{
  background-color: white;
}
