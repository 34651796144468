.no-apps-placeholder {
  width: 330px;
  margin: 0 auto;
  font-size: 28px;
  line-height: 46px;
  height: 95px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  color: transparent;

  svg.dashed {
    width: 64px;
    height: 64px;
    top: 0;
    opacity: .5;
    fill: currentColor;
    text-align: center;

    &:first-of-type {
      position: absolute;
      left: 0;
    }

    &:last-of-type {
      position: absolute;
      right: 0;
    }

    &.add-tile {
      transition: opacity 0.2s ease;
      margin: 0 auto;
    }
  }

  .app_wrapper .add-tile {
    opacity: .85;
  }

  .app_wrapper:hover .add-tile {
    opacity: 1;
  }

  .no-role-warning {
    position: absolute;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    opacity: .9;
    width: 900px;
    left: 50%;
    margin-left: -450px;
    bottom: -30px;
    max-width: 100vw;
  }
}

#activate.app_wrapper a.activate-link {
  width: 64px;
  height: 64px;
  color: #fff;

  & + p {
    width: auto;
    font-size: 16px;
    margin-top: 12px;
  }
}
