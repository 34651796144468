.apps,
.more,
.other {
  text-align: center;
}

.apps .intro-links {
  padding: 2rem 0 0;

  .btn {
    background-color: #0b7ea4;
    margin-bottom: 1rem;
    -webkit-box-shadow: 0 0 5px 0 rgba(255, 255, 255, .4);
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, .4);
  }

  .btn:hover {
    background-color: #36637e;
    -webkit-box-shadow: 0 0 12px 0 rgba(255, 255, 255, .8);
    box-shadow: 0 0 12px 0 rgba(255, 255, 255, .8);
  }

  .btn.btn-link {
    font-size: 1.3rem;
    color: #0c88b0;
    background: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn.btn-link:hover {
    text-decoration: none;
  }
}

.intro {
  font-size: 18px;
  line-height: 1.8rem;
  margin-top: -.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
}

.apps .intro {
  color: #fff;
}

.apps .intro a,
.apps .intro a:link,
.apps .intro a:visited {
  color: #cef1ff;
}

.apps .intro a:hover {
  color: #f3fafe;
}

.apps .company_switcher {
  text-align: center;
  margin: 0 auto 3rem;
}

.apps .company_switcher > a {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 200;
}

.apps h1,
.more h3,
.other h3 {
  color: #48525a;
  margin: 1rem auto;
  font-size: 48px;
  font-weight: 100;
  line-height: 1.25em;
}

.app {
  padding: 15px;
  display: inline-block;
  text-align: center;
  margin: 0 14px 44px;
  cursor: pointer;
  vertical-align: top;
  width: 196px;
  height: 196px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
}

.app:hover {
  -webkit-box-shadow: 0 1px 12px 0 rgba(102, 102, 102, .4);
  box-shadow: 0 1px 12px 0 rgba(102, 102, 102, .4);
}

.app .app_icon {
  margin: 32px auto 0;
  background-size: auto 100%;
  min-width: 72px;
  height: 72px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: middle;
}

.more .app_icon,
.other .app_icon {
  min-width: 32px;
  max-width: 176px;
  height: 32px;
  margin-top: 3px;
}

.app p {
  min-width: 142px;
  font-size: 18px;
  opacity: .7;
  margin: 10px auto 0;
  line-height: 1.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.app_actions {
  display: none;
  margin-top: .5rem;
}

.app.dropdown .app_container {
  height: 196px;
  width: 196px;
  margin: -15px;
  padding: 15px;
}

.app.dropdown .dropdown-menu {
  margin-top: -25px;
  width: 100%;
  position: relative;
  top: 0;
}

.more .app,
.other .app {
  height: 196px;
  cursor: default;
  text-align: left;
  padding: 24px 32px;
  width: 360px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
}

.more .app:hover,
.other .app:hover {
  -webkit-box-shadow: 0 5px 20px 0 rgba(102, 102, 102, .25);
  box-shadow: 0 5px 20px 0 rgba(102, 102, 102, .25);
}

.more .app_icon,
.other .app_icon {
  margin: 0 8px 0 0;
  background-position: 0 0;
  float: left;
}

.more .icon_shape,
.more .icon_shadow,
.other .icon_shape,
.other .icon_shadow {
  width: 72px;
  height: 72px;
}

.more .icon_shadow,
.other .icon_shadow {
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, .25));
}

#activate.app_wrapper > a {
  width: 110px;
  height: 110px;
  display: block;
  margin: 0 auto;
}

#activate.app_wrapper > a > img.icon_image {
  opacity: .7;
  top: 0;
}

.more .app_wrapper,
.other .app_wrapper {
  float: left;
  margin: 0;
}

.more .app_wrapper img.icon_image,
.other .app_wrapper img.icon_image {
  top: 9px;
  width: 54px;
  max-height: 54px;
  margin-bottom: 0;
  object-fit: contain;
}

.more .app_wrapper .icon_shape > img.icon_image.icon_image_full,
.other .app_wrapper .icon_shape > img.icon_image.icon_image_full {
  top: 0;
  width: 72px;
  max-height: 72px;
}

.app_desc_container {
  -webkit-box-shadow: none;
  box-shadow: none;
  float: right;
  width: 200px;
  margin: 0 0 0 20px;
  padding: 0;
  white-space: nowrap;
}

.app .app_desc {
  color: #48525a;
  display: block; /* Fallback for non-webkit */
  max-width: 280px;
  height: 52px; /* Fallback for non-webkit */
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  margin: .25rem 0 1rem;
  -webkit-line-clamp: 3;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.more .app_desc,
.other .app_desc {
  height: 72px;
  -webkit-line-clamp: 4;
  display: -webkit-box;
}

.app_desc_container .pan-button.ant-btn-sm {
  padding: 6px 12px;
  font-size: 12px;
}

.app p,
.app .app_desc {
  cursor: text;
}

.app.dropdown p.dropdown-toggle {
  cursor: pointer;
}

.app:hover .app_desc {
  color: #48525a;
}

.more .app p,
.other .app p {
  margin-top: 3px;
}

.more .app:hover p,
.other .app:hover p {
  opacity: 1;
}

.pan-button + a.learn_more {
  margin-left: 8px;
}

a.learn_more {
  color: #0ba4e8;
  font-size: 14px;
  white-space: nowrap;
}

a.learn_more::after {
  content: "\f178";
  font-family: 'FontAwesome';
  margin-left: 8px;
}

@media screen and (max-width: 414px) {
  .more .app,
  .other .app {
    max-width: -webkit-calc(100vw - 32px);
    max-width: -moz-calc(100vw - 32px);
    max-width: calc(100vw - 32px);
  }

  .app_desc_container {
    max-width: -webkit-calc(100% - 95px);
    max-width: -moz-calc(100% - 95px);
    max-width: calc(100% - 95px);
  }
}

@media screen and (max-width: 320px) {
  a.learn_more::after {
    display: none;
  }
}

a.learn_more:hover {
  color: #01a6e3;
}

.apps {
  padding: 30px 0 4rem;
}

.more {
  background: #f3fafe;
  margin: 0;
  padding: 1rem 0;
}

.other {
  background: #fff;
  margin: 0;
  padding: 1rem 0 0;
  border-top: 1px solid #ced5d5;
}

.more img,
.other img {
  max-width: 100%;
}

.app_wrapper {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin: 0 auto 32px;
  position: relative;
}

.app_wrapper a:focus {
  outline: 0;
}

.app_wrapper p {
  width: 140px;
  margin: .75rem 1rem 0;
  color: #fff;
  font-size: 16px;
}

.app_wrapper p span {
  display: block;
  display: -webkit-box;
  max-width: 140px;
  min-height: 44px;
  max-height: 60px;
  line-height: 1.3;
  -webkit-line-clamp: 3;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.app_wrapper p a {
  color: #fff;
}

.app_wrapper img {
  position: relative;
  top: -90px;
  margin-bottom: -50px;
}

.icon_shadow,
.icon_shape {
  display: block;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  position: relative;
}

.icon_shadow {
  background: url(../../images/gray-tile.svg) no-repeat;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, .25));
}

.icon_shape {
  background-color: #ecf0f0;
  /* fallback to rounded corners for not support mask-image */
  border-radius: 22%;
  -webkit-mask-image: url(../../images/gray-tile.svg);
  mask-image: url(../../images/gray-tile.svg);
}

#activate .icon_shape,
#activate .icon_shape:before,
#activate .icon_shadow:hover,
#activate .icon_shadow:hover::before {
  background: #91cbdf;
}

#activate .icon_shadow,
#activate .icon_shadow::before {
  box-shadow: none;
}

.app_wrapper .actions {
  display: inline-block;
  position: absolute;
  margin-top: -20px;
  font-size: 18px;
  left: 0;
  width: 100%;
  text-align: center;
}

.app_wrapper .actions .fa {
  display: none;
}

.app_wrapper .icon_shadow:hover .actions .fa {
  display: inline-block;
}

.app_wrapper .dropdown-menu {
  margin-left: 5px;
  margin-top: -10px;
  border-radius: 0 15px 15px 15px;
  float: none;
  border: 0;
  background: #e8ecec;
  box-shadow: 0 12px 18px rgba(0, 0, 0, .3);
}

.app_wrapper .dropdown-menu .app_name {
  text-align: center;
  margin: 5px 2px 5px 0;
}

.app_wrapper .dropdown-menu .app_name .fa {
  position: absolute;
  margin-top: 3px;
  margin-left: 3px;
}

.app_wrapper .dropdown-menu a {
  width: 184px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon_caption {
  opacity: .9;
}

.app_wrapper:hover .icon_caption {
  opacity: 1;
}

#apps .app_wrapper a.icon_shadow {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, .25));
  /* animation-duration: .1s;
  animation-name: iconMouseHover; */
}

#apps .app_wrapper:hover a.icon_shadow {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, .5));
  /* animation-duration: .1s;
  animation-name: iconMouseHover; */
}
/* @keyframes iconMouseHover {
  from {
    margin-top: 32px;
  }
  to {
    margin-top: 20px;
  }
} */

#apps .app_wrapper .icon_shape {
  background-color: rgb(232, 236, 236);
  background-position: initial initial;
  background-repeat: initial initial;
}

#apps .app_wrapper:hover .icon_shape {
  background-color: #fff;
  /* animation-duration: .1s;
  animation-name: pMouseHover; */
}
/* @keyframes pMouseHover {
  from {
    margin-top: 10px;
    opacity: .7;
  }
  to {
    margin-top: 22px;
    opacity: 1;
  }
} */

.app_wrapper img.icon_image {
  width: 84px;
  height: 84px;
  top: 13px;
  object-fit: contain;
}

.app_wrapper .icon_shape > img.icon_image.icon_image_full {
  top: 0;
  width: 110px;
  height: 110px;
}

#apps #activate.app_wrapper img.icon_image {
  opacity: 0.7;
  width: 110px;
  height: 110px;
  top: 0;
}

#apps #activate.app_wrapper img.icon_image:hover {
  opacity: 1;
}

.apps .text-center button {
  background-color: #0b7ea4;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 0 5px 0 rgba(255, 255, 255, .4);
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, .4);

  &:hover {
    background-color: #36637e;
    -webkit-box-shadow: 0 0 12px 0 rgba(255, 255, 255, .8);
    box-shadow: 0 0 12px 0 rgba(255, 255, 255, .8);
  }
}

.apps .text-center a:hover {
  text-decoration: none;
}

.apps .ant-tooltip {
  pointer-events: none;
}

.apps .icon_shadow + .ant-badge-count {
  top: -5px;
  right: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .25);
}

.apps .icon_shadow + .ant-badge-count.ant-badge-multiple-words {
  right: 12px;
}

.apps .pan-menu-item .ant-badge-status.ant-badge-not-a-wrapper {
  padding: 0 0 0 1em;
}

.apps .pan-menu-item .ant-badge-status.ant-badge-not-a-wrapper .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.apps .pan-menu-item .ant-badge-status.ant-badge-not-a-wrapper .ant-badge-status-text {
  display: none;
}

.apps .app_wrapper .ant-scroll-number-only > p {
  display: block;
  width: auto;
  margin: 0;
}

.v2 {
  .apps {
    padding-top: 50px;
    padding-bottom: 15px;
    min-height: 200px;
    position: relative;

    .my-apps {
      margin-top: 12px;
      max-width: 100vw;
    }

    .intro-header {
      height: 65px;
      color: #fff;
      font-size: 52px;
      font-weight: 400;
      margin: 14px auto 50px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .intro-login-btn {
      height: 52px;
      font-size: 22px;
      font-weight: 300;
      line-height: 20px;
      padding: 15px 50px;
      border-radius: 26px;
      margin-bottom: 50px;
      border-width: 1px;
    }

    .sparky-login-btn {
      background: #006FCC;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 50px;
      min-width: 180px;
      padding: 10px 16px 10px 16px;
    }

    .intro-links {
      padding: 0 0 30px;

      .learn-more-link {
        color: #fff;
      }
    }

    .icon_shadow,
    .icon_shape,
    .icon_shape > img.icon_image {
      width: 64px;
      height: 64px;

      &.icon_image_full {
        width: 64px;
        height: 64px;
        top: 0;
      }
    }

    .app_wrapper img.icon_image {
      width: 52px;
      height: 52px;
      top: 6px;
      margin-bottom: 0;
    }

    .actions {
      margin-top: -15px;
    }

    .app_wrapper {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 30px;
      }

      > p {
        font-size: 12px;
        width: 120px;
        margin: 5px 5px 0;

        .icon_caption {
          min-height: 14px;
          max-height: 45px;
        }
      }

      .pan-dropdown {
        .icon_shadow::after {
          content: '';
          position: absolute;
          background: none;
          width: 20px;
          height: auto;
          top: 0;
          bottom: 0;
          right: -19px;
        }

        .icon_shadow.open::after,
        .icon_shadow:hover::after {
          background: url('../../images/caret-down-white.min.svg') center right no-repeat;
          background-size: 16px 16px;
        }
      }

      .pan-menu {
        border-radius: 4px;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
        border: solid 1px #c8cbce;
      }
    }

    .scroll-to-more {
      position: absolute;
      bottom: 10px;
      right: 25px;

      &,
      &:hover {
        color: #fff;
      }
    }

    .icon_shadow + .ant-badge-count {
      top: -12px;
      height: 18px;
      line-height: 18px;
      box-shadow: none;

      p {
        font-size: 12px;
      }
    }

    .pan-menu-item .ant-badge-status.ant-badge-not-a-wrapper {
      padding: 0 0 0 .5em;

      .ant-badge-status-dot {
        width: 6px;
        height: 6px;
      }
    }
  }

  .app_wrapper .icon_shadow {
    filter: none !important;
  }

  .more-loading {
    height: 500px;
  }

  .more,
  .other {
    .icon_shape,
    .icon_shadow {
      width: 64px;
      height: 64px;

      & > img.icon_image.icon_image_full {
        width: 64px;
        max-height: 64px;
      }
    }

    .app_wrapper img.icon_image {
      top: 6px;
      width: 52px;
      max-height: 52px;
    }

    .app {
      width: 360px;
      height: 190px;
      padding: 24px;
      margin: 10px;
      box-shadow: none;
      border-radius: 4px;
      border: solid 1px #c8cbce;
      background-color: #fff;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        z-index: 10;
        left: 4px;
        top: -1px;
        width: calc(100% - 8px);
        background: linear-gradient(to right, rgba(218, 220, 222, 0), #0ba4e8, rgba(218, 220, 222, 0));
      }

      .app_desc_container {
        margin-left: 10px;
        width: 230px;
      }

      .app_display_name {
        color: #1d2124;
      }

      .app_desc {
        line-height: 1.29;
        color: #7f868c;
      }

      .learn_more {
        display: inline-block;
        font-size: 14px;
        height: 17px;
        line-height: 17px;
        vertical-align: middle;
      }

      .learn_more::after {
        content: '';
        background: url('../../images/caret-right-blue.min.svg') no-repeat;
        width: 16px;
        height: 16px;
        line-height: 16px;
        margin-left: 0;
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .v2 .apps .intro-header {
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  .v2 .apps .intro-header {
    font-size: 32px;
  }
}
