.header-with-tip-margin {
  margin-left: 10px;
}
.header-with-tip{
  display: flex;
  flex-direction: row
}
.header-with-tip-popup .ant-popover-content .ant-popover-arrow{
  background-color: rgba(0,0,0,0.8);
  border-width: 5px;
  width: auto;
  border-top-color: unset;
  border-left-color: unset;
}
.header-with-tip-popup .ant-popover-content .ant-popover-inner-content{
  background-color: rgba(0,0,0,.8);
  color: white;
  width: 300px;
  border-radius:4px;
}
