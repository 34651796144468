$assetPath: "../../images/brand-bkg-gray.png";
$border-color: #d4d4d4;
$border-value: 1px solid $border-color;

.setup-base, .setup-progress, .simple-setup-progress, .fake-root-setup {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url($assetPath);
  background-color: #fff;
  background-position: 0 0;
}

.setup-base {
  width: 100%;
  flex: 1;
  padding: 40px 0;
  position: relative;

  .setup-help-label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #333;

    li {
      line-height: 20px;
    }
  }

  .setup-help {
    position: absolute;
    bottom: 32px;
    right: 32px;
    background-color: #54b79f;
    width: 63px;
    height: 63px;
    color: white;
    font-size: 12px;
    border: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    > span {
      margin: 0;
    }

    //border-radius: 80%;
  }

  .pan-steps {
    height: 2px;
    z-index: 1;
    position: absolute;

    .ant-steps-item-finish, .ant-steps-item-process {
      .ant-steps-icon-dot {
        border: 1px solid #fff;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        background: #85C8FF;
      }
    }

    .ant-steps-item-finish {
      > .ant-steps-item-tail {
        &:after {
          background: #85C8FF;
        }
      }
    }

    .pan-steps-step {
      .ant-steps-item-tail, .ant-steps-item-icon {
        margin: 0;
        padding: 0;
      }

      .ant-steps-icon-dot {
        left: 0;
        background: #FFFFFF;
        border: 1px solid #B8B8B8;
      }

      .ant-steps-item-tail {
        &:after {
          height: 2px;
          width: calc(100% - 1px);
          margin-left: 2px;
        }
      }
    }

    .ant-steps-item-content {
      display: none;
    }
  }

  .setup-card {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 2px;
    font-style: normal;
    background: white;

    .setup-card-header {
      background: #f9f9f9;
      padding: 25px 25px 0 25px;
      border-bottom: $border-value;

      .setup-title, h1 {
        font-weight: 300;
        font-size: 30px;
        line-height: 32px;
      }

      .setup-description {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #808080;
        padding: 9px 0 25px 0;
      }
    }

    .setup-card-body {
      padding: 0 25px;
    }

    .badge-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      height: 79px;
      width: 79px;
      position: absolute;
      left: (-79 / 2)+px;
      top: (-79 / 2)+px;
      z-index: 2;
      background: #fff;
      border: 2px solid #eaeaea;

      > div {
        height: 36px;
        width: 36px;
      }
    }
  }

  .setup-required-field-label::after {
    content: '*';
    color: #8F000E;
    margin-left: 4px;
  }

  .setup-base-footer {
    border-top: $border-value;
    height: 72px;
    padding: 0 25px;
  }

  .setup-item {
    margin: 10px 0;

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #333333;
    }
  }

  .setup-drawer {
    position: absolute;
    top: 0;
    margin-left: 100%;
    background-color: #c4c4c4;
    height: 100%;

    &.open {
      width: 315px;
      visibility: visible;
    }

    &.close {
      width: 0;
      visibility: collapse;
    }
  }
}
