.ela-activation {
  color: #fff;
  display: inline-block;
  white-space: nowrap;

  &::before {
    content: "";
    background-color: #fff;
    height: 16px;
    width: 1px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin: 0 0.7em;
  }
}
