@import "../../common";

.app-access-sidebar {
  background-color: white;
}

.app-access-sidebar, .pan-radio-radioGroup, .rbac-menu {
  width: 100%;
  flex-direction: column;
  >li {
    >label {
      width: 165px;
      display: flex;
      padding-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rbac-submenu-border {
  border-bottom: 1px solid #dadada;
  >div label {
    white-space: pre-wrap;
  }
  .pan-menu-item {
    @extend .hbox;
    @extend .middle;
    label {
      display: flex;
      width: 165px;
      :nth-child(2) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.rbac-radio-group {
  overflow: auto;
  display: flex;
  border: none;
}

.rbac-menu {
  padding-top: 0;
  border: 0;
  height: 100%;
  border: none;
}

