.ant-badge-count,
.ant-badge-dot {
  .pan-badge-status-warning & {
    background-color: #faad14;
  }

  .pan-badge-status-error & {
    background-color: #d94949;
  }

  &.pan-badge-text {
    font-size: 9px;
    text-transform: uppercase;
  }
}
