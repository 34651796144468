.app_header_info {
  display: block;
  border: 0;
  margin: 0;
  padding: 0;

  .btn-link {
    font-size: 14px;
    color: #fff;
  }

  .btn-link:hover {
    text-decoration: none;
  }

  .app_summary + .btn-link {
    padding-left: 0;
  }
}

.portal.app_details {
  a.diagram {
    display: inline-block;
  }

  img.marketplace-lightbox-image {
    max-width: 100%;
  }

  .gallery_image_urls {
    margin-bottom: 2em;
  }

  &.v2 {
    #app .page-header.v2-blue-bkg {
      padding-top: 100px;
    }

    .byline {
      color: #fff;
    }
  }

  .app_requirements .social svg {
    color: #777;
    width: 30px;
    height: 34px;
  }
}
