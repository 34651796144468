/**
*
* Created by vsuthar on 9/21/18 File AssignRole
* Project: App Portal ©PaloAlto Networks
*/
.RBAC__assign_role_side_panel {
  border-right: 1px solid rgba(0, 0, 0, .125);
  background: #f2f2f2;
  width: 240px
}

.RBAC__assign_role_page {
  background-color: #EFEFEF;
}

.RBAC__assign_role_menu {
  background-color: transparent;
  border: 0;
}

.RBAC__app_assign_role_container {
  padding: 1rem;
}

.RBAC__app_assign_role_header {
  font-size: 1.4rem;
  font-weight: 400;
  color: #646464;
  white-space: nowrap;
  padding-bottom: .5rem;
}

.RBAC__app_assign_tip_button {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.RBAC__app_assign_tip_button:focus {
  outline: none;
}

.RBAC__edit_permission_button {
  flex: 0.5;
  border-radius: 0 4px 4px 0;
  border-left-width: 0;
  border-color: #d9d9d9;
  height: 32px;

  &:hover {
    border-color: #C8CBCE;
  }
}

.RBAC_app_assign_select {
  .ant-select-selection {
    border-radius: 4px 0 0 4px;
  }
}

.RBAC_app_assign_go_back {
  padding: 20px 0px 0px 34px
}

.RBAC_AssignPage_Tag {
  text-align: center;
  height: 24px;
  font-size: 14px;
  margin: 3px;

  .anticon anticon-cross {
    color: black;
    font-size: 16px;
  }
}
