.edit-form-display-field {
  &:first-of-type {
    margin-top: 20px;
  }

  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 20px;
  }
}

.edit-form-body .ant-form-item-label > label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-form-splitter {
  border-color: #dadcde;
}

.edit-form-footer .pan-button {
  min-width: 80px;

  & + .pan-button {
    margin-left: 8px;
  }
}
