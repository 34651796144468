.my-apps {
  .dropdown-submenu__menu {
    display: flex;
    justify-content: space-between;
    padding-right: 0;

    & > a {
      flex: 1;
    }
  }

  .instance-provisioning-disabled {
    cursor: not-allowed;
    color: #7f868c;

    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .instance-provisioning,
  .instance-provisioning-disabled {
    &::after {
      margin-left: 3px;
      content: '(in progress)';
      font-size: 11px;
    }
  }

  .dropdown-submenu__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #333;

    .dropdown-submenu {
      position: absolute;
      z-index: 1049;

      .dropdown-submenu__ul {
        position: relative;
        display: none;
        background-color: #fff;
        list-style: none;
        border-radius: 4px;
        outline: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border: solid 1px #c8cbce;
        margin: 0;
        padding: 4px 0;
        text-align: left;

        .dropdown-submenu__item {
          white-space: nowrap;
          cursor: pointer;
          padding: 5px 12px;
          list-style: none;
          line-height: 16px;
          padding: 10px 15px;
          font-size: 14px;
          transition: all 0.3s;

          &:hover {
            background-color: rgba(11, 164, 232, 0.1);
          }
        }

        .dropdown-submenu__item--disabled {
          cursor: not-allowed;
          color: #7f868c;

          &:hover {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  .dropdown-submenu__btn {
    border: none;
    width: 24px;
    height: 36px;
    padding: 10px 4px;
    margin: -10px 0 -10px 5px;
    background-color: #fff;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(11, 164, 232, 0.1);
    }

    &:focus {
      background-color: rgba(11, 164, 232, 0.3);
    }

    svg {
      height: 100%;
      pointer-events: none;
    }
  }

  .dropdown-submenu__btn--single {
    position: absolute;
    right: 13px;
    top: 0;
    padding: 0;
    width: 20px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;

    svg {
      width: 16px;
      pointer-events: none;
      color: white;
    }
  }
}
