.summary-total-class {
  font-size: 1.5rem;
  margin-top: 40px;
  font-weight: bold;
}
.summary-total-unit{
  font-size: 30px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  white-space: pre;
}

.summary-total-name{
  font-weight: 400;
}

.summary-total-tag-line {
  font-size: small;
}

.collapsible-card > .ant-card-head {
  background-color: transparent;
  border-width: 0px;
  color:white;
}
.lsc-summary{
  background-color: #009ace;
  margin: 0 10px;
  margin-bottom: 40px;
  color: white;
  position: sticky;
  min-width: 310px;
  max-width: 310px;
  top: 95px;
  transition: all .5s ease-in-out;
  transform-origin: left top 10px;
  z-index: 1;
  align-self: flex-start;
}
.summary-unit{
  margin-left: 6px;
  font-size: 18px;
  font-weight: 400;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  white-space: pre;
}
