.custom-role-page {
  padding: 17px 40px 20px 40px;
  background: white;
  height: 100%;
  flex: 1;

  .search-bar {
    margin-bottom: 10px;
  }
}

.uc-badge {
  background: #f3723c;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-left: 11px;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  min-width: 20px;
}
