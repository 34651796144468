/**
*
* Created by vsuthar on 2018-12-20 File RoleTable.scss
* Project: App Portal ©PaloAlto Networks
*/
.instanceRole {
  margin:0;
}

.RBAC__rtable_app_render > div{
  border-bottom: 1px solid #E8E8E8;
  line-height: 1.3;
}
.RBAC__rtable_instanceRole > div {
  border-bottom: 1px solid #E8E8E8;
  line-height: 1.3;
}
.RBAC__rtable_app_render > div:last-child, .RBAC__rtable_instanceRole > div:last-child{
  border: none;
}

.RBAC_roleName div {
  padding: 9px 0;
  border-bottom: 1px solid #E8E8E8;

  &:last-child {
    border-bottom: none;
  }
}

.applications .appName {
  &:last-child > div {
    border-bottom: none;
  }
}
.appName div:last-child {
  border-bottom: 1px solid #E8E8E8;
}



