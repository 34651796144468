/**
*
* Created by vsuthar on 10/8/18 File UserAutoComplete
* Project: App Portal ©PaloAlto Networks
*/

.RBAC__autocomplete {
  border:none;
  width: 100%;
  margin-bottom: 16px;
}
.RBAC__dropdownClassName .ant-select-dropdown-menu-item-active{
  background-color: rgba(0, 0, 0, 0.05);
}
.RBAC__autocomplete .ant-select-selection--multiple .ant-select-selection__clear{
  top:50%;
}
.RBAC__search-container{
  width:100%;
  bottom: 9px;
  position: absolute;
  z-index: 10;
  margin-left: 10px;
  overflow: hidden;
  animation: progress-process 2.5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
}
.RBAC__search-bar {
  width: 20px;
  height:3px;
  background-color: #0e6f95;
  border-radius: 40%;
}

.RBAC__no_search-container{
  display: none;
}

@-webkit-keyframes progress-process{
  50% {
    transform: translateX(100%);
    margin-left:-30px;
    margin-right: -10px;
    overflow-x: hidden
  }
}

@keyframes progress-process{
  50% {
    transform: translateX(100%);
    margin-left:-30px;
    margin-right: -10px;
    overflow-x: hidden
  }
}
