@import "~@pan/cloud-base/theme.scss";
@import "~@pan/cloud-base/global";

.vbox {
  display: flex;
  flex-direction: column;
}

.hbox {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-box-fill {
  flex: 1;
}

.middle, .align-middle {
  align-items: center;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-end {
  align-self: flex-end;
}

.app-muted-label {
  color: #6d757b;
  margin: 0;
  padding: 0;
}

.divider {
  .title {
    margin-right: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #6b828a;
    font-size: 12px;
    font-weight: 600;
  }

  .line {
    border: 0.5px solid #dadada;
    flex: 1;
  }
}

.pan-button.pan-btn-alt {
  background-color: transparent;
  background-image: none;
  border-color: rgba(255, 255, 255, 0.9);
  color: #fff;
  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.1);
    background-image: none;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &[disabled],
  &.disabled {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: .7;
  }
}

.s-alert-success {
  background-color: #1faf2c;
}
.s-alert-close {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 4px;
  top: 10px;
  overflow: hidden;
  text-indent: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &:after, &:before {
    width: 1px;
  }
}

.link-btn {
  border: none;
  color: $pan-primary;
  &:hover {
    border: none;
    background: none;
    color: $pan-primary;
  }

}

.scroll-container {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
  linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
  radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
  radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background: /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
