.otp-item {
  .style-1 {
    .ant-form-item-label {
      text-transform: none;

      label {
        color: #fff;
        padding: 0;
        margin: 0;
      }
    }
  }

  .style-2 {
    .ant-form-item-label {
      text-transform: none;

      label {
        color: #808080;
        padding: 0;
        margin: 0;
      }
    }
  }

  .ant-form-explain {
    margin-top: 4px;
  }
}

.otp {
  .otp-text-input {
    width: 90%;
    background-color: #fff;
    border-radius: 4px;
    height: 32px;
    padding: 0 5px;
    border: 1px solid #c8cbce;

    &:focus,
    &:hover {
      border-color: #31b0d4;
      outline: none;
    }
  }

  > * {
    margin-right: 5px;
  }

  .otp-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    button[disabled] {
      background-color: transparent;
      border: none;
      outline: none;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.otp-modal {
  .ant-form-item-label  label {
    color: #1d2124;
  }

  .otp-btn {
    background-color: #31b0d4;
    width: 50px;
    height: 50px;
    margin: -10px 5px;
    border-radius: 7px;

    .pan-icon::before {
      font-size: 20px;
    }
  }

  .ant-spin-spinning .otp-btn {
    visibility: hidden;
  }

  .otp-item.pan-form-item {
    margin-bottom: 0;
  }
}
