/* Webfont: Lato-Black */
@font-face {
  font-family: "lato";
  font-weight: 900;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-black.6d20cff5.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-black.6cfa65c6.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: "lato";
  font-weight: 900;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-black-italic.b9c017a7.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-black-italic.0d0882bc.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: "lato";
  font-weight: 700;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-bold.21b3848a.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-bold.89b61808.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: "lato";
  font-weight: 700;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-bold-italic.e48918f9.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-bold-italic.3e6b9980.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
  font-family: "lato";
  font-weight: 100;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-hairline.73d2c045.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-hairline.7d1b926d.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: "lato";
  font-weight: 100;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-hairline-italic.863985d6.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-hairline-italic.598d0913.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: "lato";
  font-weight: 800;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-heavy.75f024ce.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-heavy.5b824a72.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: "lato";
  font-weight: 800;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-heavy-italic.353a3bb9.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-heavy-italic.e014213d.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: "lato";
  font-weight: 400;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-italic.bb5de40e.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-italic.62a9c838.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: "lato";
  font-weight: 300;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-light.e244488c.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-light.848f3a6e.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: "lato";
  font-weight: 300;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-light-italic.dcb1947b.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-light-italic.ed49088e.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: "lato";
  font-weight: 500;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-medium.a071abba.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-medium.08eb0932.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: "lato";
  font-weight: 500;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-medium-italic.3e22a536.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-medium-italic.9ba72333.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: "lato";
  font-weight: 400;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-regular.75614cfc.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-regular.1d2ca94d.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: "lato";
  font-weight: 600;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-semibold.a6069540.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-semibold.c6646559.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: "lato";
  font-weight: 600;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-semibold-italic.34b49f33.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-semibold-italic.3fdee263.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: "lato";
  font-weight: 200;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-thin.6ae7abff.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-thin.1fd1d41f.woff) format("woff");
  font-style: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: "lato";
  font-weight: 200;
  src: url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-thin-italic.46957bf0.woff2) format("woff2"),
    url(https://assets.cdn.apps.paloaltonetworks.com/fonts/lato/lato-thin-italic.546b4809.woff) format("woff");
  font-style: italic;
  text-rendering: optimizeLegibility;
}
