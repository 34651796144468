#root > .fake-root {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  &::before,
  &::after,
  > .v2-blue-bkg::before,
  > .v2-blue-bkg::after {
    content: '';
    position: absolute;
    display: block;
    opacity: 0.45;
    border-radius: 4px;
    background: #fff;
    z-index: 100;
  }

  &::before {
    left: 16px;
    top: 12px;
    width: 26px;
    height: 26px;
  }

  &::after {
    top: 290px;
    width: 432px;
    height: 12px;
    left: 50%;
    margin-left: -216px;
  }

  > .v2-blue-bkg {
    height: 350px;
    opacity: .15;
    position: relative;
    z-index: 0;

    &::before {
      top: 80px;
      width: 800px;
      height: 40px;
      left: 50%;
      margin-left: -400px;
    }

    &::after {
      top: 180px;
      width: 160px;
      height: 52px;
      border-radius: 26px;
      left: 50%;
      margin-left: -80px;
    }
  }

  * {
    pointer-events: none;
    user-select: none;
  }
}

#root.setup-root {
  .cortex-app-bar-mock,
  .fake-root-setup .blue-bkg {
    display: none;
  }
}
