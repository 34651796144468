/**
*
* Created by vsuthar on 9/21/18 File PANContent.css
* Project: App Portal ©PaloAlto Networks
*/
.PAN__vbox {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.PAN__grow {
  flex: 1
}

.PAN__Sidebar {
  background-color: white;
  border-right: 1px solid #CCC;
}

.PAN__go_back {
  padding-left: 34px;
  padding-top: 24px;
}

.PAN_SubHeader {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #6d757b;
}
