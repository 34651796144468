/**
*
* Created by vsuthar on 10/1/18 File AccountInfo
* Project: App Portal ©PaloAlto Networks
*/
.RBAC__accountInfo{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height:0;
}
.RBAC__accountInfo .ant-tabs-content{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
.RBAC__accountInfo .ant-tabs-content .ant-tabs-tabpane {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

