.roleAccess_SubHeader {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #6d757b !important;
  width: 100%;
}
