.ngf-table {
  width: 70%;
  margin: auto;
}

.ngf-dialog-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nfgw-diag-log-rate{
  color: #999
}

.LSC__ngf_dialog .ant-modal-content .ant-modal-footer {
  display: flex;
}
