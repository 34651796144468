.number-input-calc {
  width: 170px;
  .pan-input {
    border: none;
    box-shadow: none;
    &:focus {
      outline: none;
    }
    .ant-input-number-handler-wrap {
      border: 1px solid #d9d9d9;
    }
  }
  border-radius: 4px;
  border: 1px solid #C8CBCE;
  .suffix {
    font-size: 14px;
    margin-right: 10px;
  }
}
