.table.services-table {
  thead th {
    background: #f2f2f2;
    border-top: 0;
    border-bottom: 1px solid #ddd;
    padding-top: .25rem;
    text-align: left;
  }

  thead th.table-header {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.1;
    text-transform: none;
    padding-top: 2rem;
    padding-bottom: 0;
    border-top: 1px solid #ccc;
    border-bottom: 0;
  }

  thead th.table-header.button-cell {
    padding: .8rem;
    border-bottom: 1px solid #ddd;
  }

  thead:first-of-type th.table-header {
    border-top: 0;
    padding-top: 1.25rem;
  }

  thead th.table-header img.instance-app-logo {
    float: left;
    margin-top: -5px;
    margin-right: .5rem;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  thead th.table-header button.instance-add-btn {
    float: right;
    margin-top: -5px;
  }

  td.tabel-spacer {
    height: 0;
  }

  tr:first-of-type td {
    border-top: 0;
  }

  td.editable .instance-action-icon {
    visibility: hidden;
    margin: -2px 0 -2px 2px;
    width: 25px;
    height: 25px;
  }

  td.editable:hover .instance-action-icon {
    visibility: visible;
  }

  tr.instance-extension-row {
    td {
      border-top: none;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: .5em;
    }
  }

  tr.instance-extension-row td:first-child {
    padding-left: 2em;
  }
}

.instance-action-tooltip {
  pointer-events: none;
}

.table.instance-table {
  border-collapse: separate;
  border-spacing: 0;
  $border: solid 1px #c8cbce;
  $border-hr: solid 1px #edeeee;

  thead th {
    background: #fff;
    color: #49535b;
  }

  thead th + th {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      height: 16px;
      width: 1px;
      background: #49535b;
    }

    &:empty::before {
      display: none;
    }
  }

  thead th.table-header {
    padding: 20px;
    line-height: 40px;
    font-size: 18px;
    color: #1d2124;
    border-top: $border;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      z-index: 100;
      left: 4px;
      top: -1px;
      width: calc(100% - 8px);
      background: linear-gradient(to right, rgba(218, 220, 222, 0), #0ba4e8, rgba(218, 220, 222, 0));
    }
  }

  thead:first-of-type th.table-header {
    border-top: $border;
  }

  thead th.table-header img.instance-app-logo {
    margin-top: 0;
    margin-right: .5rem;
    width: 40px;
    height: 40px;
  }

  thead th.table-header button.instance-add-btn {
    margin-top: 0;
  }

  tr.instance-extension-row,
  tr.instance-row {
    &:last-of-type td {
      border-bottom: $border;

      &:first-child {
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  th:first-of-type {
    border-left: $border;
  }

  th:last-of-type {
    border-right: $border;
  }

  td {
    background: #fff;
    border-top: $border-hr;
    border-left: $border;
  }

  td:last-child {
    border-right: $border;
  }

  td.tabel-spacer {
    height: 20px;
    background: transparent;
    border: none;
  }

  td.editable .instance-actions {
    float: right;

    .instance-action-icon {
      border: none;
      background-color: transparent;
      transition: all 0.15s ease-in-out;
      position: relative;
      width: 24px;
      height: 24px;
      line-height: 24px;
      vertical-align: baseline;
      margin: -1px 0 -1px 10px;
      text-align: center;
      opacity: .7;

      svg {
        width: 16px;
        height: 16px;
        top: 2px;
        fill: currentColor;
      }

      svg,
      i.fa {
        display: inline-block;
        position: relative;
        z-index: 2;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 100px;
        display: block;
        transition: all .2s ease;
        opacity: 0;
        transform: scale(0.5);
        border: 1px solid transparent;
        z-index: 1;
      }

      &:hover::before {
        background-color: #edeeee;
        transform: scale(1);
        opacity: 1;
        border: 1px solid #c8cbce;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  td.editable .telemetry {
    padding: 5px;
    vertical-align: text-top;
  }

  .instance-status {
    svg.status-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      fill: currentColor;
    }

    &.status-color-red {
      color: #d94949;
    }

    &.status-color-green {
      color: #1faf2c;
    }

    &.status-color-yellow {
      color: #ffc528;
    }

    &.status-color-gray {
      color: #a4a9ad;
    }
  }
}
