.cortex-app-bar .app-bar-inner .app-nav#app-nav {
  margin-left: 0;
}

.sparky-hub-toggle-wrap {
  color: #fff;
  font-size: 0.75rem;
  vertical-align: middle;
  margin: 0 16px 0 -10px;

  &:empty {
    margin-right: 0;
  }

  &::before {
    content: '';
    position: relative;
    margin: 0;
    margin-right: .5rem;
    top: 4px;
    display: inline-block;
    width: 1px;
    height: 16px;
    background: #fff;
    opacity: .6;
  }
}

.sparky-hub-toggle {
  display: inline-block;
  position: relative;
  top: -2px;
  width: 32px;
  height: 16px;
  line-height: 16px;
  background-color: #707070;
  border-radius: 32px;
  vertical-align: middle;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  user-select: none;
  appearance: none;
  transition: background-color .2s;
  margin: 0 .5rem;

  &:focus {
    outline: none;
  }

  &::after {
    content: '';
    background: #fff;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
    right: auto;
    transition: left .2s;
  }

  &.sparky-hub-toggle-checked::after {
    left: 18px;
  }

  &.sparky-hub-toggle-checked .sparky-hub-toggle-svg {
    left: 2px;

    > .sparky-hub-toggle-svg-checked {
      opacity: 1;
    }

    > .sparky-hub-toggle-svg-unchecked {
      opacity: 0;
    }
  }

  &.sparky-hub-toggle-checked {
    background-color: #006fcc;
  }

  &.sparky-hub-toggle-disabled,
  &.sparky-hub-toggle-checked.sparky-hub-toggle-disabled {
    background-color: #b8b8b8;
    cursor: not-allowed;
  }
}
