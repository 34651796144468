.avatar-box {
  display: flex;
  flex-direction: column;
  margin: 0 4px;
}

.logging-calc-avatar {
  height: 100%;
  background-color: white;
}

/**
* START Override ant tag related style.
*/
.avatar-box .ant-tag-checkable:active {
  background-color: white !important;
  border: 2px solid #2a9fd8;
}

.avatar-box .ant-tag-checkable:hover{
  border: 2px solid rgba(42, 159, 216, 0.5);
}
.avatar-box .ant-tag-checkable-checked {
  border: 2px solid #2a9fd8;
}

/*.avatar-box .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {*/
  /*background-color: white !important;*/
/*}*/

/**
* END Override ant tag related style.
*/

.toggle-btn-title {
  text-align: center;
  margin-top: 16px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  width: 120px;
}


