.activated-form {
  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 24px;
  }

  .btn-manage-quota {
    padding: .5em 1em;
    margin-top: 5px;
  }

  .activated-quota-warning {
    position: relative;
    margin: 5px 0;
    padding-left: 25px;

    svg.activated-warning-icon {
      fill: #f9a145;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
    }
  }
}
