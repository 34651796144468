@mixin federal-badge {
  content: 'Federal';
  position: relative;
  top: -1px;
  margin-right: 6px;
  padding: 3px 6px;
  border-radius: 2px;
  background-color: black;
  color: white;
  font-size: 9px;
}

.activate-form-display-field {
  &:first-of-type {
    margin-top: 20px;
  }

  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 20px;
  }
}

.cdl-upgrade-field .ant-form-item-label {
  line-height: 14px;
}

.cdl-upgrade-field-invisible {
  .ant-form-item-label,
  .ant-form-explain {
    line-height: 1px;
    font-size: 1px;
    color: transparent;
  }
}

.activate-form {
  max-width: 750px;
  margin-left: 30px;

  .ant-form-item-label > label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pan-form-item.instance-field-hidden {
    display: none;
  }

  .ant-form-item-children + .ant-form-extra {
    font-size: 13px;
    line-height: 20px;
  }
}

.activate-form-splitter {
  border-color: #dadcde;
}

.activation-page {
  display: block;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  min-height: 300px; // fallback
  min-height: calc(100vh - 50px);

  > .pan-page-body {
    background: #fff;
  }
}

.activation-page.account-is-federal,
.activation-modal.account-is-federal {
  .account-name-select,
  .region-field-select {
    .ant-select-selection-selected-value {
      &:before {
        @include federal-badge;
      }
    }
  }
}

.form-footer .pan-button {
  min-width: 100px;
  padding: 9px 18px;
  height: 36px;

  &.ant-btn-primary {
    min-width: 80px;
  }

  & + .pan-button {
    margin-left: 8px;
  }

  .ant-spin-container.ant-spin-blur &.ant-btn.ant-btn-loading {
    padding: 9px 18px;
  }
}

.col-required-legend {
  flex: 1 0;
  height: 33px;
  line-height: 33px;
  text-align: left;
  vertical-align: baseline;
  font-size: 14px;
  color: #d94949;

  &::after {
    content: 'Required Field';
  }
}

.page-header-extra {
  font-weight: 200;
  font-size: 20px;
}

.field-is-federal.ant-select-dropdown-menu-item,
.activation-modal.account-is-federal .account-name,
.setup-base .customer-account-selection .federal-account {
  &::before {
    @include federal-badge;
  }
}
